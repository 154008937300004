import './App.css';
import { Card, CardActions, CardContent, CardHeader, Grid, Typography, TextField, Button, FormHelperText, OutlinedInput, FormControl, InputLabel, CardMedia } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';

function App() {

    const [showForm, setShowForm] = useState(true);
    const [showPrices, setShowPrices] = useState(false);
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [price, setPrice] = useState(0);

    function getRandomInt(min, max) {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            amount: '',
        },
        validationSchema:
            Yup.object().shape({
                name: Yup.string().min(3).max(100).required("Name is required"),
                phone: Yup.string().required("Phone is required").matches(/^\d{10}$/, "Enter Valid Phone Number"),
                amount: Yup.string().min(3).required("Amount is required").matches(/^\d+$/, "Enter Valid Amount")
            }),
        onSubmit: async values => {
            // alert(JSON.stringify(values, null, 2));
            setShowForm(false);
            setShowPrices(true);
            if (values.amount > 0) {
                setName(values.name);
                setAmount(values.amount);
                let percent = 0;
                if (values.amount <= 500) {
                    percent = getRandomInt(28, 33);
                }
                else if (values.amount > 500 && values.amount <= 700) {
                    percent = getRandomInt(38, 43);
                }
                else if (values.amount > 700 && values.amount <= 1000) {
                    percent = getRandomInt(41, 46);
                }
                else if (values.amount > 1000 && values.amount <= 1500) {
                    percent = getRandomInt(44, 48);
                }
                else if (values.amount > 1500) {
                    percent = getRandomInt(47, 51);
                }
                const price = Math.round(values.amount * (100 - percent) / 100);
                setPrice(price);
                values.percent = percent;
                values.price = price;

                try {
                    const response = await axios.post('https://anvifashionimitation.shop/api/users', values);
                } catch (error) {
                    console.error('Error sending email:', error);
                    // alert('Failed to send email');
                }
            }
        },
    });

    return (
        <div className="App">
            <header className="App-header">
                <Card raised={true} sx={{ padding: 2, margin: 2, maxWidth: '300px', backgroundColor: '#FFFFFF', color: '#333333', border: '1px solid #FFD700' }}>
                    <CardMedia
                        sx={{ width: '100%', margin: '0 auto', maxWidth: '200px' }}
                        component="img"
                        image="ANVI.jpg"
                        title="green iguana"
                    />
                    {/* <img src='ANVI.png' /> */}
                    <CardContent sx={{
                        '& .MuiInputBase-input': {
                            color: '#333333', // Text color
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333333', // Label color
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#333333', // Outline color
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#333333',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#333333',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#333333',
                        },
                    }}>
                        {showForm && (
                            <>
                                <Typography variant="h4" sx={{ color: '#333333' }}>ऑफर किंमत मिळवा</Typography>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={1} justifyContent="center"
                                        alignItems="center">
                                        <Grid item xs={12}>
                                            <FormControl size='small' error={Boolean(formik.touched["name"] && formik.errors["name"])} >
                                                <TextField
                                                    // inputRef={inputRef}
                                                    id={`input-name`}
                                                    type={'text'}
                                                    value={formik.values["name"]}
                                                    name={"name"}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    label={"नाव"}
                                                    variant="standard"
                                                />
                                                {formik.touched["name"] && formik.errors["name"] && (
                                                    <FormHelperText error id={`standard-weight-helper-text-${"name"}`}>
                                                        {formik.errors["name"]}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl size='small' error={Boolean(formik.touched["phone"] && formik.errors["phone"])} >
                                                <TextField
                                                    // inputRef={inputRef}
                                                    id={`input-phone`}
                                                    type={'number'}
                                                    value={formik.values["phone"]}
                                                    name={"phone"}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    label={"फोन"}
                                                    variant="standard"
                                                />
                                                {formik.touched["phone"] && formik.errors["phone"] && (
                                                    <FormHelperText error id={`standard-weight-helper-text-${"phone"}`}>
                                                        {formik.errors["phone"]}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl size='small' error={Boolean(formik.touched["amount"] && formik.errors["amount"])} >
                                                <TextField
                                                    // inputRef={inputRef}
                                                    id={`input-amount`}
                                                    type={'number'}
                                                    value={formik.values["amount"]}
                                                    name={"amount"}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    label={"बिलाची रक्कम"}
                                                    variant="standard"
                                                />
                                                {formik.touched["amount"] && formik.errors["amount"] && (
                                                    <FormHelperText error id={`standard-weight-helper-text-${"amount"}`}>
                                                        {formik.errors["amount"]}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" disabled={formik.isSubmitting}
                                                // type="submit"
                                                onClick={formik.handleSubmit} type="submit">ऑफर किंमत मिळवा</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>

                        )}
                        {showPrices && (
                            <>

                                <Typography variant='h5' sx={{ color: '#333333' }}>धन्यवाद {name}</Typography>
                                <Typography variant='h5' sx={{ color: '#333333', marginBottom: 1 }}>बिलाची रक्कम ₹{amount}</Typography>
                                <Typography variant='h5' sx={{ color: '#333333', marginBottom: 1 }}>ऑफर किंमत</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button variant="contained" ><Typography variant='h3'>₹{price}</Typography></Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" sx={{ marginTop: 1 }}
                                            onClick={() => {
                                                setShowForm(true);
                                                setShowPrices(false);
                                                setPrice(0);
                                            }} type="submit">पुन्हा प्रयत्न करा</Button>
                                    </Grid>
                                </Grid>

                            </>
                        )}
                    </CardContent>
                    <CardActions></CardActions>
                </Card>
                <Card raised={true} sx={{ padding: 2, margin: 2, backgroundColor: '#FFFFFF', color: '#333333', border: '1px solid #FFD700' }}>
                    <CardMedia
                        sx={{ height: 140 }}
                        component="img"
                        image="Offer500.jpeg"
                        title="green iguana"
                    />
                </Card>
                <Card raised={true} sx={{ padding: 2, margin: 2, backgroundColor: '#FFFFFF', color: '#333333', border: '1px solid #FFD700' }}>
                    <CardMedia
                        sx={{ height: 140 }}
                        component="img"
                        image="Offer200.jpeg"
                        title="green iguana"
                    />
                </Card>

            </header>
        </div>
    );
}

export default App;
